
/**
This allows user to browse and select user to be added
 */
import Vue from "vue";
import ListUsersTable from "./ListUsersTable.vue";

export default Vue.extend({
  components: { ListUsersTable },
  name: "form-builder-data-collector",
  props: {
    title: {
      type: String,
      required: false,
      default: "Form Filler"
    },
    emitOnAction: {
      type: Boolean,
      default: false
    },
    queryOverride: {
      type: Object,
      default() {}
    },
    additionalBulkTableActions: {
      type: Array,
      default() {
        return [];
      }
    },
    topButtons: {
      default() {
        return [];
      },
      type: Array
    },
    fromAgentPicker: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      validationData: {}
    };
  },
  methods: {
    topButtonClicked(topButton: string) {
      this.$emit("topButtonClicked", topButton);
    },
    closed() {
      this.$emit("closed");
    }
  }
});
